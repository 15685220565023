import { assignParentCompanyToPrefix } from "../../../api/BarcodeScan";
import ParentCompanyModal from "./ParentCompanyModal";
import editIcon from "../../../assets/icons/edit.png";

const ButtonShowEditCompromisedModal = ({
  GTIN,
  refreshCallback,
  showCompromisedModal,
  closeCompromisedModal,
  openCompromisedModal,
  disabled,
}) => {
  return (
    <>
      {/* <button
        className="bg-primary rounded px-3 py-1.5 text-white"
        onClick={openCompromisedModal}
      >
        <img src={editIcon} alt="Edit" width="16" height="16" />
      </button> */}
      <img
        src={editIcon}
        alt="Edit"
        width="16"
        height="16"
        className={`${
          disabled
            ? "opacity-50 cursor-not-allowed"
            : "cursor-pointer hover:opacity-80"
        }`}
        onClick={disabled ? undefined : openCompromisedModal}
      />
      <ParentCompanyModal
        refreshCallback={refreshCallback}
        assignToCallbackParam={GTIN}
        assingToCallBack={assignParentCompanyToPrefix}
        closeCompromisedModal={closeCompromisedModal}
        showCompromisedModal={showCompromisedModal}
      />
    </>
  );
};

export default ButtonShowEditCompromisedModal;
